import React, {useState,useEffect} from 'react'
import {Modal,Button} from 'react-bootstrap'
import GeneralForm from './form/form'



function WdModal({reveal,setReveal,setRevealSuccess}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {setReveal(false)};
  const handleShow = () => setShow(true);

  useEffect(() => {

    setShow(reveal)

  },[reveal]);

  return (
    <>


      <Modal centered={true} show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
        <GeneralForm setRevealSuccess={setRevealSuccess} handleClose={handleClose}/>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default WdModal
