import React,{useState} from 'react'
import { useFormik } from 'formik';
import {Form,ProgressBar} from 'react-bootstrap'
import {Step1,Step2,Step3,Step4} from './steps'
import {GTMMetaforaSubmit} from '../../lib/gtag'

function GeneralForm({handleClose,setRevealSuccess}){


  const [step,setStep] = useState(1)




const validate = values => {
 const errors = {};



 if(step == 1){

   if(!values.from_address){
     errors.from_address = 'Υποχρεωτικό πεδίο';
   }

   if(!values.from_flour){
     errors.from_flour = 'Υποχρεωτικό πεδίο';
   }

 }

 if(step == 2){

   if(!values.to_address){
     errors.to_address = 'Υποχρεωτικό πεδίο';
   }

   if(!values.to_flour){
     errors.to_flour = 'Υποχρεωτικό πεδίο';
   }

 }

 if(step == 4){


   if(!values.telephone){
     errors.telephone = 'Υποχρεωτικό πεδίο';
   }

 }



 return errors;
};


  var storedvalues = sessionStorage.getItem('formvalues') && JSON.parse(sessionStorage.getItem('formvalues')) || {}



  const formik = useFormik({
 initialValues: {
   from_address:storedvalues.from_address || '',
   from_flour:storedvalues.from_flour || "0",
   from_packet:storedvalues.from_packet || false,
   from_workers:storedvalues.from_workers || false,
   from_anabatorio:storedvalues.from_anabatorio || false,
   to_address:storedvalues.to_address || '',
   to_flour:storedvalues.to_flour || "0",
   to_workers:storedvalues.to_workers || false,
   to_anabatorio:storedvalues.to_anabatorio ||false,
   antikeimena: storedvalues.antikeimena || '',
   store:storedvalues.store || false,
   email:'',
   telephone: '',
   name:''


 },
 validate,

 onSubmit: values => {
   if(step !== 4){
     console.log(values)

     sessionStorage.setItem('formvalues', JSON.stringify(values));

     setStep(step +1)

   }

   else {
     console.log(values,"values ...submiting")

     let url = `${process.env.DRUPAL_URL}/wdapi/webform/submit`

let data = {
"webform_id": "removal",
"webform_data": {
  from_address:values.from_address,
  from_floor:values.from_flour,
  from_packaging:values.from_packet,
  from_staff:values.from_workers,
  from_lifting:values.from_anabatorio,
  to_address:values.to_address,
  to_floor:values.to_flour,
  to_staff:values.to_workers,
	to_lifting:values.to_anabatorio,
	items:values.antikeimena,
	storage:values.store,
  email:values.email,
  phone:values.telephone,
  name:values.name

}
}

console.log(data,"data")

 fetch(url,{
   method:'POST',
   headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body:JSON.stringify(data)

}).then(res => res.json()).then(res => {
  console.log(res)
  if(res.errors){
    alert("Κάτι πήγε λάθος")
    return;
  }
  GTMMetaforaSubmit()
  sessionStorage.removeItem('formvalues')
  setRevealSuccess(true)
  handleClose()
}).catch(err => console.log)
   }


 }
});


  return (

    <Form className=""  onSubmit={formik.handleSubmit}  autoComplete="off" noValidate>
    {/* <button type="button" onClick={()=> {let res = validate(formik.values); console.log(res);}} className="btn btn-danger">test</button> */}
    <div className="d-flex align-items-center mb-4">
    {(step >1) &&
      <div style={{color:'#00656D'}} onClick={()=>setStep(step-1)} className="d-flex align-items-center hoverable">
      <span style={{width:'15px',height:'15px'}}  className="arrow left"></span>
      <span>Πίσω</span>
      </div>
    }
    <button onClick={()=> handleClose()} style={{float:'none',fontSize:'1.8rem'}} type="button" className="close ml-auto"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
    </div>
    <ProgressBar className="mb-4" now={step*25}/>


      {(step == 1) && <Step1 formik={formik}/>}
      {(step == 2) && <Step2  formik={formik}/>}
      {(step == 3) && <Step3  formik={formik}/>}
      {(step == 4) && <Step4  formik={formik}/>}



    </Form>

  )

}

export default GeneralForm
