import React from 'react'
import {Form} from 'react-bootstrap'

function Step1({formik}){

  return (
    <>
      <h3 className="text-center">Αφετηρία</h3>
      <Form.Group >
        <Form.Label htmlFor="from_address">Διεύθυνση</Form.Label>
        <Form.Control  isInvalid={formik.touched.from_address && formik.errors.from_address} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.from_address}  type="text" id="from_address" name="from_address" placeholder="π.χ. Σολωνος 80, Αθήνα" />
        <Form.Control.Feedback type="invalid">
          {formik.errors.from_address}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group >
        <Form.Label htmlFor="from_flour">Όροφος</Form.Label>
        <Form.Control  isInvalid={formik.touched.from_flour && formik.errors.from_flour} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.from_flour} as="select"   id="from_flour" name="from_flour">
          <option value="0">Ισόγειο</option>
          <option value="1">1ος</option>
          <option value="2">2ος</option>
          <option value="3">3ος</option>
          <option value="4">4ος</option>
          <option value="5">5+</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {formik.errors.from_flour}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <p>Στην αφετηρία θα χρειαστώ:</p>
        <Form.Check
          required
          name="from_packet"
          label="Πακετάρισμα των αντικειμένων"
          onChange={formik.handleChange}
          isInvalid={formik.touched.from_packet && formik.errors.from_packet}
          value={formik.values.from_packet}
          checked={formik.values.from_packet}
          id="from_packet"
          type="switch"
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          required
          name="from_workers"
          label="Βοήθεια από προσωπικό"
          onChange={formik.handleChange}
          isInvalid={formik.touched.from_workers && formik.errors.from_workers}
          value={formik.values.from_workers}
          checked={formik.values.from_workers}
          id="from_workers"
          type="switch"
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          required
          name="from_anabatorio"
          label="Ανυψωτικό μηχάνημα"
          onChange={formik.handleChange}
          isInvalid={formik.touched.from_anabatorio && formik.errors.from_anabatorio}
          value={formik.values.from_anabatorio}
          checked={formik.values.from_anabatorio}
          id="from_anabatorio"
          type="switch"
        />
      </Form.Group>
      <div className="d-flex justify-content-end request-button">
        <button  className="btn btn-request" type="submit" name="submit">Επομενο</button>
      </div>
    </>
  )



}

function Step2({formik}){

  return (
    <>
      <h3 className="text-center">Προορισμός</h3>
      <Form.Group >
        <Form.Label htmlFor="to_address">Διεύθυνση</Form.Label>
        <Form.Control  isInvalid={formik.touched.to_address && formik.errors.to_address} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.to_address}  type="text" id="to_address" name="to_address" placeholder="π.χ. Σμύρνης 12, Αιγάλεω" />
        <Form.Control.Feedback type="invalid">
          {formik.errors.to_address}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group >
        <Form.Label htmlFor="to_flour">Όροφος</Form.Label>
        <Form.Control  isInvalid={formik.touched.to_flour && formik.errors.to_flour} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.to_flour} as="select"   id="to_flour" name="to_flour">
        <option value="0">Ισόγειο</option>
        <option value="1">1ος</option>
        <option value="2">2ος</option>
        <option value="3">3ος</option>
        <option value="4">4ος</option>
        <option value="5">5+</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {formik.errors.to_flour}
        </Form.Control.Feedback>
      </Form.Group>



      <Form.Group>
        <p>Στον προορισμό θα χρειαστώ:</p>
        <Form.Check
          required
          name="to_workers"
          label="Βοήθεια από προσωπικό"
          onChange={formik.handleChange}
          isInvalid={formik.touched.to_workers && formik.errors.to_workers}
          value={formik.values.to_workers}
          checked={formik.values.to_workers}
          id="to_workers"
          type="switch"
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          required
          name="to_anabatorio"
          label="Ανυψωτικό μηχάνημα"
          onChange={formik.handleChange}
          isInvalid={formik.touched.to_anabatorio && formik.errors.to_anabatorio}
          value={formik.values.to_anabatorio}
          checked={formik.values.to_anabatorio}
          id="to_anabatorio"
          type="switch"
        />
      </Form.Group>

      <div className="d-flex align-items-center justify-content-end request-button">



        <button  className="btn btn-request" type="submit" name="submit">Επομενο</button>

      </div>
    </>
  )



}

function Step3({formik}){

  return (
    <>
      <h3 className="text-center">Αντικείμενα</h3>
      <Form.Group >
        <Form.Label htmlFor="antikeimena">Περιγραφή αντικειμένων</Form.Label>
        <Form.Control  isInvalid={formik.errors.antikeimena} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.antikeimena}  as="textarea" rows={3} id="antikeimena" name="antikeimena" placeholder="π.χ. 3 κούτες, 1 κουζίνα, 1 καναπές" />
        <Form.Control.Feedback type="invalid">
          {formik.errors.antikeimena}
        </Form.Control.Feedback>
      </Form.Group>





      <Form.Group>
        <Form.Check
          required
          name="store"
          label="Θα χρειαστώ απηθήκευση των αντικειμένων"
          onChange={formik.handleChange}
          isInvalid={formik.touched.store && formik.errors.store}
          value={formik.values.store}
          checked={formik.values.store}
          id="store"
          type="switch"
        />
      </Form.Group>

      <div className="d-flex align-items-center justify-content-end request-button">



        <button  className="btn btn-request" type="submit" name="submit">Επομενο</button>

      </div>
    </>
  )



}

function Step4({formik}){

  return (
    <>
      <h3 className="text-center">Ολοκλήρωση</h3>
      <Form.Group >
        <Form.Label htmlFor="telephone">Τηλέφωνο</Form.Label>
        <Form.Control  isInvalid={formik.touched.telephone && formik.errors.telephone} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.telephone} type="tel" id="telephone" name="telephone"  />
        <Form.Control.Feedback type="invalid">
          {formik.errors.telephone}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group >
        <Form.Label htmlFor="email">Email</Form.Label>
        <Form.Control  isInvalid={formik.touched.email && formik.errors.email} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} type="email" id="email" name="email"  />
        <Form.Control.Feedback type="invalid">
          {formik.errors.email}
        </Form.Control.Feedback>
      </Form.Group>



      <Form.Group >
        <Form.Label htmlFor="name">Όνομα</Form.Label>
        <Form.Control  isInvalid={formik.errors.name} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} type="text" id="name" name="name"  />
        <Form.Control.Feedback type="invalid">
          {formik.errors.name}
        </Form.Control.Feedback>
      </Form.Group>



      <div className="d-flex align-items-center justify-content-end request-button">



        <button  className="btn btn-request" type="submit" name="submit">Υποβολή</button>

      </div>
    </>
  )



}



export {Step1,Step2,Step3,Step4}
